// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

$(document).ready(function() {
	$('#pagepiling').pagepiling({
    scrollingSpeed: 700,
    verticalCentered: true,
    anchors: ['main-page', 'concept-page', "portfolio", 'about-work-page', 'search-page', "vacansies", 'why-page', 'contacts'],
    menu: "#menu"
  });
});